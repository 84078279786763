.container {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 100%;
  max-height: 125px;
}

.controls {
  display: none;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px 0;
}

.raiseControls {
  /* position: absolute; */
  bottom: 38px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.sliderContainer {
  position: relative;
  height: 40px;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.raiseSlider {
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  outline: none;
  opacity: 0;
  z-index: 2;
  position: relative;
}

.sliderTrack {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 14px;
  background: #4790acb0;
  border-radius: 22px;
  transform: translateY(-50%);
}

.sliderThumb {
  position: absolute;
  top: 50%;
  left: 0;
  width: 60px;
  height: 30px;
  background: #0066cc;
  border-radius: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.fold {
  background-color: #cc0000;
}

.call {
  background-color: #009900;
}

.raise {
  background-color: #0066cc;
}

.fold,
.call,
.raise {
  padding: 12px 24px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fold:hover,
.call:hover,
.raise:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.fold:disabled,
.call:disabled,
.raise:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.raiseSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1px;
  height: 30px;
  background: transparent;
  cursor: pointer;
}

.raiseSlider::-moz-range-thumb {
  width: 1px;
  height: 30px;
  background: transparent;
  cursor: pointer;
}
