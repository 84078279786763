.seat {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background: #333;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.playerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.seat.folded .playerImage {
  filter: brightness(30%);
  transition: filter 0.3s ease;
}

.stack {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  font-size: 0.8em;
  padding: 2px 0;
  text-align: center;
}

.position {
  position: absolute;
  top: 70px;
  font-size: 0.8em;
  z-index: 1;
}

.seat.active {
  background: #0066cc;
  transform: scale(1.1);
}

.seat3.active {
  transform: translateX(-50%) scale(1.1);
}

.seat.folded {
  background: #cc0000;
}

.chipsContainer {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  max-width: 60px;
}

.seat1 .chipsContainer {
  left: 100%;
  bottom: 50%;
  transform: translateY(50%);
}

.seat2 .chipsContainer {
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.seat3 .chipsContainer {
  top: 150%;
  left: 50%;
  transform: translateX(-50%);
}

.seat4 .chipsContainer {
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.seat5 .chipsContainer {
  right: 100%;
  bottom: 50%;
  transform: translateY(50%);
}

.mainPlayer .chipsContainer {
  bottom: 220%;
  left: 50%;
  transform: translateX(-50%);
}

.mainPlayer {
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.bet {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 0.9em;
  z-index: 10;
  display: none;
}

.seat1 {
  bottom: 25%;
  left: 10px;
}

.seat2 {
  top: 25%;
  left: 10px;
}

.seat3 {
  top: 1%;
  left: 50%;
  transform: translateX(-50%);
}

.seat4 {
  top: 25%;
  right: 10px;
}

.seat5 {
  bottom: 25%;
  right: 10px;
}

.holeCards {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.cardsContainer {
  display: flex;
  gap: 10px;
}

.card {
  width: 50px;
  height: 75px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  background-color: white;
  animation: cardReveal 0.5s ease-out;
}

.card.hearts,
.card.diamonds {
  color: red;
}

.card.spades,
.card.clubs {
  color: black;
}

.card:hover {
  transform: translateY(-5px);
}

.chip {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: -18px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 3px solid rgba(255, 255, 255, 0.8);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  color: black;
}

.chip::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.3);
}

.chip1 {
  background-color: #f2f2f2;
}
.chip5 {
  background-color: #ff4d4d;
}
.chip10 {
  background-color: #4d4dff;
}
.chip25 {
  background-color: #33cc33;
}
.chip100 {
  background-color: #1a1a1a;
}

.holeCardsMini {
  position: absolute;
  top: -10px;
  display: flex;
  gap: 2px;
}

.holeCardMini {
  width: 20px;
  height: 30px;
  background-color: #90ee90;
  border: 1px solid #008000;
  border-radius: 3px;
  transition: opacity 0.3s ease;
}

.seat.folded .holeCardsMini,
.seat.folded .holeCards {
  opacity: 0;
}

.dealerButton {
  position: absolute;
  width: 30px;
  height: 30px;
  color: #fffc00;
  background-color: #80b677;
  border: 2px solid #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 10;
  top: 50px;
  right: 55px;
}

@keyframes cardReveal {
  0% {
    transform: scale(0.1) rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotateY(0deg);
    opacity: 1;
  }
}

.chip.animating {
  animation: moveToCenter 1s forwards;
}

@keyframes moveToCenter {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--targetX), var(--targetY));
  }
}
