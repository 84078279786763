
.communityCards {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.pot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.potChipsContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.pot .chip {
  margin: -18px 0;
}

.cardsContainer {
  display: flex;
  gap: 10px;
}

.card {
  width: 50px;
  height: 75px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  background-color: white;
  animation: cardReveal 0.5s ease-out;
}

.card.hearts,
.card.diamonds {
  color: red;
}

.card.spades,
.card.clubs {
  color: black;
}

.card:hover {
  transform: translateY(-5px);
}

@keyframes cardReveal {
  0% {
    transform: scale(0.1) rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotateY(0deg);
    opacity: 1;
  }
}