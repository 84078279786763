.container {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  background: #27ae60;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.pokerTable {
  width: calc(100dvw - 70px);
  height: 75dvh;
  max-width: 1200px;
  max-height: 800px;
  background: radial-gradient(ellipse at center, #1e9f1e 0%, #003300 100%);
  border-radius: 150px;
  position: relative;
  margin-top: 20px;
  border: 15px solid #8d4906;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
}

/* .communityCards {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.pot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.potChipsContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.pot .chip {
  margin: -18px 0;
}

.cardsContainer {
  display: flex;
  gap: 10px;
}

.card {
  width: 50px;
  height: 75px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  background-color: white;
  animation: cardReveal 0.5s ease-out;
}

.card.hearts,
.card.diamonds {
  color: red;
}

.card.spades,
.card.clubs {
  color: black;
}

.card:hover {
  transform: translateY(-5px);
}

@keyframes cardReveal { */
  /* 0% {
    transform: scale(0.1) rotateY(180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotateY(0deg);
    opacity: 1;
  }
} */
/* 
Global styles that might be needed for the table
:global(html),
:global(body) {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background: #27ae60;
  color: white;
  font-family: 'Roboto', sans-serif;
} */
